<template>
  <div class="page-main-bg">
    <v-container class="main-container">
      <!--TOOL_BAR-->
      <tool-bar v-bind="toolBarProps" />
      <!-- DUES_FILTERS -->
      <dues-filters
        :dues-config="duesConfig"
        @updateFilters="updateFilters"
        @queryBuilder="queryBuilder"
        @resetPagination="resetPagination"
      />
      <!-- loader Rows -->
      <loader v-if="isPageLoading" v-bind="loaderProps" />

      <!--no Dues-->
      <no-data v-else-if="!dues.length" v-bind="noDataProps" />

      <!-- Expandable Rows Table -->
      <expandable-table
        v-else
        :dues="dues"
        :assignmentStatus="duesConfig.assignmentStatus"
        :nonEditableDuesStatus="nonEditableDuesStatus"
        @setInstallmentStatus="beforeUpdateInstallment"
        @open-due-details="setSideDetails"
      />
      <SideDues
        v-show="isSideDuesOpened"
        :assignmentTypes="duesConfig.installmentType"
        :DueDetails="DueDetails"
        @closeDetails="isSideDuesOpened = false"
      />
      <sideDuesDetailes
        v-show="isDueDetailsOpened && !isCustomer"
        ref="sideDetails"
        :assignmentStatus="duesConfig.assignmentStatus"
        :nonEditableDuesStatus="nonEditableDuesStatus"
        :assignmentId="DueDetails.id"
        :assignmentTypes="duesConfig.installmentType"
        :isSideDetailsLoading="isSideDetailsLoading"
        @close-side-details="closeSideDetails"
        @toggleSideDetails="isDueDetailsOpened = !isDueDetailsOpened"
        @refreshDues="getAllDues"
        @due-update="updateDue"
        @setInstallmentStatus="beforeUpdateInstallment"
        @setSideDetailsLoading="isSideDetailsLoading = false"
        @update-fixed-amount="updateDue"
      />

      <AddDue
        v-bind="{
          isClaimRentType: true,
          fromSideDetails: false,
          dialogState: dueConfirmModalStatus,
          assignment: installmentData
        }"
        @closeDialog="dueConfirmModalStatus = false"
        @addDue="updateInstallmentStatus"
      />

      <!-- Pagination Rows Table -->
      <div class="text-center mt-6 mb-5" v-if="pagination.pagesCount > 1">
        <Pagination :pagination="pagination" @onChange="getAllDues()" />
      </div>
    </v-container>
  </div>
</template>

<script>
import duesFilters from '@/components/Dues/DuesFilters.vue'
import sideDuesDetailes from '@/components/Dues/sideDuesDetailes.vue'
import Loader from '@/components/helper/loader.vue'
import noData from '@/components/helper/noData.vue'
import Pagination from '@/components/helper/pagination.vue'
import ToolBar from '@/components/listing/ToolBar'
import SideDues from '@/components/modals/dues/SideDues.vue'
import confirmationMixin from '@/mixins/confirmationMixin.js'
import { duesService } from '@/services'
import { mapGetters, mapMutations, mapState } from 'vuex'
import ExpandableTable from '../components/Dues/ExpandableTable.vue'

export default {
  name: 'Dues',
  components: {
    ToolBar,
    duesFilters,
    ExpandableTable,
    Pagination,
    Loader,
    sideDuesDetailes,
    SideDues,
    noData,
    AddDue: () => import('@/components/modals/dues/AddDue.vue')
  },
  mixins: [confirmationMixin],
  data() {
    return {
      breadcrumbs: [
        {
          text: 'المالية ',
          disabled: false,
          exact: true,
          link: true,
          to: { path: '/dashboard/dues' }
        },
        { text: 'المطالبات المالية', disabled: true }
      ],
      isPageLoading: false,
      dueConfirmModalStatus: false,
      duesConfig: {},
      dues: [],
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 5,
        count: 0
      },
      noDataConfig: {
        text: 'ليس هناك مطالبات مالية متاحة بعد',
        subTitle:
          'المطالبات المالية بجميع أنواعها ستكون متاحة في هذه القائمة مع   <br />  إمكانية عرض التفاصيل الخاصة بكل مطالبة على حده',
        icno: require('@/assets/img/money.png')
      },
      duesFilters: {},
      pageTilte: 'المطالبات المالية',
      DueDetails: {},
      isSideDuesOpened: false,
      isDueDetailsOpened: false,
      isSideDetailsLoading: false,
      nonEditableDuesStatus: ['مدفوعة', 'ملغيّة'],
      installmentData: {}
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    ...mapGetters('accountState', ['isCustomer']),
    toolBarProps() {
      return {
        breadcrumbs: this.isCustomer
          ? [{ text: 'العمليات المالية', disabled: true }]
          : this.breadcrumbs,
        title: this.isCustomer ? 'العمليات المالية' : 'المطالبات المالية'
      }
    },
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'property-col mb-2 shadow-border-radius px-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    noDataProps() {
      return {
        noDataText: this.noDataConfig.text,
        noDataSubTitle: this.noDataConfig.subTitle,
        noDataIcon: this.noDataConfig.icno
      }
    },

    getActiveCustomerRole() {
      return JSON.parse(localStorage.getItem('activeCustomerRole'))
    }
  },
  created() {
    this.getDuesConfig()
    this.getAllDues()
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    resetPagination() {
      this.pagination.pageNumber = 1
    },
    closeSideDetails() {
      this.isDueDetailsOpened = false
      this.DueDetails = Object.assign({})
    },
    updateSideDetailsAssigment(DueId) {
      if (this.isDueDetailsOpened) {
        this.$refs.sideDetails.getSingleDue(DueId)
      }
    },
    openSideDetailsInCustomerMood(DueDetails) {
      // logic for customer mode
      this.DueDetails = DueDetails
      this.isSideDuesOpened = true
    },
    setSideDetails(DueDetails) {
      if (this.isCustomer) {
        this.openSideDetailsInCustomerMood(DueDetails)
      } else {
        this.isSideDetailsLoading = true
        this.DueDetails = DueDetails
        this.isDueDetailsOpened = true
      }
    },
    async getDuesConfig() {
      try {
        const { data } = await duesService.getDuesFilters()
        this.duesConfig = data
        this.isCustomer && delete data.assignmentStatus
      } catch {
        this.addNotification({
          text: 'لم يتم تحميل بيانات المطالبات بشكل صحيح',
          color: 'error'
        })
      }
    },
    async getAllDues() {
      this.isPageLoading = true
      try {
        const { data } = await duesService.getAllDues(
          {
            ...(this.isCustomer && { userId: this.user._id }),
            ...(this.isCustomer && { userType: this.getActiveCustomerRole }),
            sortByCreatedAt: -1,
            ...this.duesFilters,
            ...this.pagination
          },
          this.currentEnv._id
        )
        this.pagination.pagesCount = Math.ceil(
          data.count / this.pagination.pageSize
        )
        this.pagination.count = data.count
        this.dues = data.assignments
      } catch {
        this.addNotification({
          text: 'لم يتم تحميل المطالبات بشكل صحيح',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    async updateDue(updatedDue) {
      const { _id, ...data } = updatedDue
      try {
        const { data: res } = await duesService.updateDue(data, _id)

        this.updateSideDetailsAssigment(updatedDue._id)

        if (res.assignment.needsApproval) {
          this.showConfirmationPopupMixin()
        }
        this.addNotification({
          text: 'تم تعديل المطالبه بشكل صحيح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم يتم تعديل المطالبه بشكل صحيح',
          color: 'error'
        })
      } finally {
        this.getAllDues()
      }
    },
    updateFilters(filters) {
      this.duesFilters = filters
    },
    async confirmModalunderLegalAction() {
      return await this.$root.$confirm.open({
        title: 'سيتم تحويل كل مطالبات العقد الى قيد الإجراء القانوني',
        message:
          'هل أنت متأكد من تحويل كل المطالبات الى قيد الإجراء القانوني ؟',
        confirmTxt: 'تاكيد',
        cancelTxt: 'إلغاء',
        img: {
          path: require('@/assets/img/svgs/exclamationMark.svg'),
          width: 20,
          height: 56
        },
        options: {
          color: 'primary',
          width: 460
        }
      })
    },
    async confirmModalDueAction() {
      return await this.$root.$confirm.open({
        title: 'سيتم تحويل كل مطالبات العقد لمستحقة',
        message:
          'هل أنت متأكد من تحويل كل المطالبات الخاصة بهذا العقد لمستحقة ؟',
        confirmTxt: 'تاكيد',
        cancelTxt: 'إلغاء',
        img: {
          path: require('@/assets/img/svgs/exclamationMark.svg'),
          width: 20,
          height: 56
        },
        options: {
          color: 'primary',
          width: 460
        }
      })
    },
    async beforeUpdateInstallment(installmentData) {
      this.installmentData = installmentData
      const assignmentStatus = installmentData.assignmentStatus

      const itemAssignmentStatus = this.dues.find(
        (due) => due._id === installmentData._id
      ).assignmentStatus

      if (itemAssignmentStatus === assignmentStatus) {
        return
      }

      // TODO: on hold
      // if (
      //   itemAssignmentStatus === 'قيد الإجراء القانوني' &&
      //   assignmentStatus === 'مستحقة'
      // ) {
      //   const status = await this.confirmModalDueAction()
      //   if (status) {
      //     this.updateInstallmentStatus()
      //   }
      //   return
      // }

      // if (assignmentStatus === 'قيد الإجراء القانوني') {
      //   const status = await this.confirmModalunderLegalAction()
      //   if (status) {
      //     this.updateInstallmentStatus()
      //   }
      //   return
      // }

      if (assignmentStatus === 'تم التحصيل') {
        this.dueConfirmModalStatus = true
        return
      }

      this.updateInstallmentStatus()
    },
    async updateInstallmentStatus(data = {}) {
      const assignmentStatus = this.duesConfig.assignmentStatus.find(
        (assignment) =>
          assignment.nameAr === this.installmentData.assignmentStatus
      )

      this.installmentData.assignmentStatus = assignmentStatus.code
      delete this.installmentData.paymentStatus
      await this.updateDue({ ...this.installmentData, ...data })
      await this.getAllDues()
      this.dueConfirmModalStatus = false
    },
    async queryBuilder(params) {
      // del the unsed params
      delete params.date

      // remove the empty params
      Object.keys(params).forEach(
        (key) => params[key] === '' && delete params[key]
      )
      this.duesFilters = params
      // get the filtered Dues
      await this.getAllDues()
    }
  }
}
</script>

<style lang="scss" scoped></style>
